// Variables
$gs-primary: #004cab; // Blue ALS brand
$gs-secondary: #f0b41c; // Yellow
$default-background: #f5f5f5; // Grey
$darken-default-background: darken($default-background, 40%); // Grey
// https://mui.com/material-ui/customization/palette/
$mui-success-main: #2e7d32; // Green
$mui-success-alert-bg: #edf7ed; // pale success green
$mui-error-light: #ef5350;
$app-box-bg: white;
$app-box-padding: 24px;
$app-box-padding-mobile: 16px;
$app-breadcrumb-space: 37px;

$break-point-tablet-portrait: 600px;
$break-point-tablet-landscape: 900px;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // from amplify package we removed:
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  min-height: 100vh;
}

// from amplify package that we removed
* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

[data-amplify-authenticator] {
  display: none;
  [data-amplify-container] {
    [data-amplify-header] {
      text-align: center;
      margin-top: 100px;
    }
    [data-amplify-footer] {
      text-align: center;
      font-size: 80%;
    }
  }
}

.App-content {
  background-color: $default-background;
  min-height: 100vh;
  padding: 15px;
}

.App-box,
.App-panel-horizontal {
  border-radius: 10px;
  background-color: $app-box-bg;
  padding: $app-box-padding;
  height: calc(100vh - #{$app-breadcrumb-space} - (#{$app-box-padding} * 4));
  overflow-x: auto;
  overflow-y: auto;
  white-space: normal;
  transition: border 300ms ease-out;
  position: relative;
  &.active {
    border: 1px $gs-primary solid;
    padding: $app-box-padding - 1;
  }

  &.App-box-horizontal {
    height: inherit;
  }

  h1 {
    margin-top: 0px;
    margin-bottom: 0.83em;
    font-size: 1.5em;
  }

  * ~ h1 {
    margin-top: unset;
    margin-bottom: unset;
    font-size: unset;
  }

  h2 {
    margin-top: 0px;
    .highlight {
      color: $gs-primary;
    }
  }
  * ~ h2 {
    // h2 not at the top of .app-box
    margin-top: 16px;
  }
  h2 ~ .sub-title,
  div.h3 ~ .sub-title {
    color: $darken-default-background;
    margin-top: -20px;
    span {
      margin-right: 20px;
      font-size: 90%;
    }
    border-bottom: 1px solid $darken-default-background;
  }

  .bottom-button {
    position: absolute;
    bottom: $app-box-padding;
  }
  .custom-label {
    font-weight: bold;
    min-width: 100px;
    margin-right: 16px;
  }
  .custom-link {
    text-decoration: none;
    color: $gs-primary;
    &:hover {
      color: darken($gs-primary, 20%);
    }
    &:visited,
    &:active {
      color: $gs-primary;
    }
  }
}

.App-box.horizontal {
  border-bottom: 1px solid $gs-primary;
  padding: 0px;
}

.App-panel-horizontal {
  border-bottom: 1px solid $gs-primary;
  min-height: unset;
}

.Dash-box {
  padding: 0px !important;
  border-radius: 3px;
  h3 {
    margin-top: 0;
    padding: 5px;
    // border-bottom: 1px solid $gs-primary;
    // background-color: lighten(#f0b41c, 20%);
    background-color: $default-background;
  }
}

.uppercase {
  text-transform: uppercase;
}

nav.breadcrumb {
  margin-bottom: 10px;
  line-height: 27px;
  li {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
    // margin-bottom: 5px;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    svg.MuiChip-icon {
      margin-right: 6px;
    }
    span:empty {
      cursor: pointer;
      display: none;
    }
  }
}

// <Sidebar>
.sidebar {
  transition: width 0.2s ease;
  width: 0;
}

.sidebar.open {
  width: 270px;
}

.project-switch {
  border-bottom: white;
  & * {
    color: white;
  }
  input {
    color: white;
  }
  label {
    color: whitesmoke;
  }
  fieldset {
    color: white;
    border-color: white;
  }
}

kbd > kbd {
  background-color: lightgrey;
  border-radius: 4px;
  color: rgb(0, 0, 0);
  font: 300 0.875em/1.5 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 3px 9px;
}

kbd > kbd + span {
  font-weight: 600;
  margin: 0 1px;
}
// </Sidebar>

.jobs-card {
  position: relative;
  padding: 10px;
  border: 1px solid darken($default-background, 10%);
  border-radius: 10px;
  div.jobs-title {
    position: relative;
    font-size: 90%;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    span {
      display: inline-block;
      vertical-align: middle;
    }
    .job-creator {
      font-weight: lighter;
      margin-left: 5px;
      font-size: 90%;
    }
  }
  .dateTime {
    margin-bottom: 5px;
    color: darken($default-background, 40%);
    small {
      display: inline-block;
      vertical-align: middle;
      padding-right: 8px;
    }
  }
  .jobAction {
    position: absolute;
    right: 10px;
    bottom: 10px;
    button,
    a {
      margin-top: 5px;
    }
  }
}

div.show-more-show-less {
  position: relative;
  margin-top: -16px;
  div.collapsible-text-content {
    overflow: hidden;
    padding-bottom: 10px;
  }
  div.collapisble-text-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    padding-bottom: 10px;
    pointer-events: none;
    background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.99),
      10%,
      rgba(255, 255, 255, 0.01) 25%
    );
    display: initial;
    width: 100%;
    &.open {
      display: none;
    }
  }
  button.text-like-button {
    background: none;
    border: none;
    box-shadow: none;
    color: $gs-primary;
    cursor: pointer;
    &.less {
      display: none;
      &.open {
        display: block;
      }
    }
    &.more {
      display: block;
      &.open {
        display: none;
      }
    }
  }
}

// <Visualizer>
.visual-charts {
  height: 100%;
  border: 1px solid darken($default-background, 10%);
  overflow: auto;
  width: calc(100%) !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  position: relative;
  padding-right: 20px;

  /* Strip-Logs */
  .icon-buttons {
    top: 45px;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    cursor: grab;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $default-background;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: darken($default-background, 10%);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $darken-default-background;
  }
  /* Handle meet corner */
  &::-webkit-scrollbar-corner {
    background: $default-background;
  }

  > .visual-chart-wrap {
    margin-left: 80px;
  }

  > .visual-chart-wrap ~ .visual-chart-wrap {
    margin-left: 0;
  }
}

.visual-chart-wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
}

.global-tick-wrap {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: fit-content;
  width: 100%;
}

.visual-chart-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 40px;
  margin: 0;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 5;
  text-align: center;
  border-bottom: 1px solid darken($default-background, 10%);
  height: max-content;
  overflow-x: hidden;
  height: 45px;
  font-size: 90%;
}

.visual-chart-name-log {
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 20px;
  margin: 0;
  text-align: center;
  height: max-content;
  overflow-x: hidden;
  font-size: 90%;
}

h5.visual-chart-name-log {
  font-weight: normal;
  font-size: 60%;
}

.visual-chart-name-log-wrap {
  background-color: white;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 5;
  overflow-x: hidden;
  height: 45px;
  border-bottom: 1px solid darken($default-background, 10%);
}

.chart-tick {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.chart-tick-line {
  width: 100%;
  border-bottom: 3px solid darken($default-background, 10%);
}

.chart-tick-text {
  position: sticky;
  left: 0;
  top: 0;
  color: black;
  font-size: 12px;
  padding: 0 5px;
  background: #fff;
  margin: 0;
  z-index: 1;
}

.visual-chart-depth {
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 40px;
  margin: 0;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 5;
  text-align: center;
  border-bottom: 1px solid darken($default-background, 10%);
  height: max-content;
  overflow-x: hidden;
  height: 45px;
  width: 100%;
}

.visual-option {
  display: flex;
  justify-content: center;
}

.visual-select-form {
  width: 100%;
}

.visual-container {
  padding-top: 1rem;
  justify-content: space-around;
  height: 100%;
  overflow-x: scroll;

  @media (max-width: $break-point-tablet-portrait) {
    justify-content: flex-start;
  }
}

.chart-container {
  display: flex;
  justify-content: center;
  position: relative;
  height: fit-content;
  width: fit-content;
  margin-top: 10px;
}

[role='tablist'] {
  gap: 2px;
}

.tab {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  background-color: #cccccc;
  border-color: #aaa;
  position: sticky;
  top: 0;
  z-index: 1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  user-select: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.tab:hover {
  background-color: #ddd;
}

.tab:active,
.tab.active {
  background-color: #e9eef4;
}

// Logs Explorer
div.logs-explorer-logs-list {
  width: 100%;
}

h1.log-explorer-description.title {
  font-size: medium;
  font-weight: 700;
}

div.MuiFormControl-root.logs-list {
  width: 100%;
  min-width: 120px;
  max-width: 550px;
  margin: 8px 0;
}

h2.MuiDialogTitle-root.log-heading,
div.MuiDialogContent-root.log-content {
  padding-left: 25px;
  padding-right: 25px;
}

// <ImageStripLog>

.chart-container {
  display: flex;
  justify-content: center;
  position: relative;
  height: fit-content;
  width: fit-content;
  margin-top: 10px;

  // Adjust Numerical Log
  figure {
    div {
      top: 48px !important;
    }
  }
}

.visual-image-container {
  margin: 0;
  width: fit-content;
  display: inline-block;
  height: 100%;
}

.chart-image {
  transform: rotate(90deg) translateY(-100%) scale(1);
  transform-origin: left top;

  /* Hide broken link icon */
  &[alt]:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    font-family: 'Helvetica';
    font-weight: 300;
    line-height: 2;
    text-align: center;
    content: attr(alt);
  }
}

// </Visualizer>

pre.api-response,
div.api-response {
  white-space: pre-wrap;
  overflow: auto;
  max-height: 300px;
  padding: 5px;
}

form small.error {
  color: $mui-error-light;
}

@media (max-width: $break-point-tablet-portrait) {
  // 600 px
  .App-box {
    padding: $app-box-padding-mobile;
  }
}
@media (max-width: $break-point-tablet-landscape) {
  // 900 px
  .App-box {
    min-height: inherit;
    .bottom-button {
      position: inherit;
      bottom: auto;
      margin-top: 16px;
    }
  }
}

// Import Partials
@import url(./pages/_tables.scss);
